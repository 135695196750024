
h1 {
    font-size: 40px;
    // font-size: 2.08vw;
    font-weight: bold;
    color: #777;

    @media (min-width: 768px) and (max-width: 1199px) {
        // For Tablet screens
        font-size: 45px;
        // font-size: 5vw;
    }

    @media screen and (max-width: 767px) {
        // For mobile screens
        font-size: 28px;
        // font-size: 6.5vw;
    }
}

h2 {
    // font-size: 24px;
    font-size: 20px;
    // font-size: 1.25vw;
    font-weight: bold;
    color: #777;

    @media (min-width: 768px) and (max-width: 1199px) {
        // For Tablet screens
        font-size: 20px;
        // font-size: 1.8vw;
    }

    @media screen and (max-width: 767px) {
        // For mobile screens
        font-size: 17px;
        // font-size: 4vw;
    }
}

.descriptionText {
    font-size: 16px;
    // font-size: 0.835vw;
    color: #777;
    font-weight: normal;

    @media (min-width: 1200px) {
        // For Desktop screens
        max-width: 75%;
        margin: auto;
    }

    @media (min-width: 768px) and (max-width: 1199px) {
        // For Tablet screens
        font-size: 19px;
        // font-size: 1.6vw;
    }

    @media screen and (max-width: 767px) {
        // For mobile screens
        font-size: 17px;
        // font-size: 4vw;
    }
}

.seperator {
    background-color: black;

    .container {
        background-image: linear-gradient(90deg, black, white, black);
        background-repeat: no-repeat;
        height: 1px;
    }
}

.section {
    background-image: url("./../../public/assets/images/radialOverlay.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: top;
    background-color: black;
    text-align: center;
    padding: 80px 0;

    @media screen and (max-width: 767px) {
        // For mobile screens
        background-size: 180%;
    }
}

.gameCard {
    padding: 10px;
    img {
        width: 100%;
    }

    .aboutCard {
        text-align: left;
        padding: 0px 30px 30px 30px;
        color: #777;
        font-weight: normal;
        
        @media (min-width: 768px) and (max-width: 1199px) {
            // For Tablet screens
            padding: 0px 40px 30px 40px;
        }

        @media screen and (max-width: 767px) {
            // For mobile screens
            padding: 0px 40px 30px 40px;
        }

        .gameName {
            color: #fff;
            font-size: 24px;
            font-weight: bold;
        }

        .playDemoLink {
            display: block !important;
            color: #ffc107 !important;
        }
    }
}

.gameCard.disabled {
    img {
        filter: brightness(0.4);
    }

    .aboutCard {
        color: #777;
        .gameName {
            color: white;
        }
        .playDemoLink {
            color: #777 !important;
        }
    }
}

.serviceSection {
    .cardContainer {
        padding: 20px 0px;
        img {
            width: 60px;
            height: 60px;
        }

        .icons{
            font-size: 60px;
            color: #ffc107;
        }

        .cardTitle {
            font-size: 16px;
        }
    }
}

.aboutSection{
    .cardContainer {
        padding: 20px 60px;
        color: #777;

        @media (min-width: 992px) and (max-width: 1199px) {
            // For Tablet screens - intermediate resolution
        }

        @media (min-width: 1200px) {
            // For Desktop Screens
        }
    }
}

.whyUsSection {
    .descriptionText{
        max-width: 75%;
        margin: auto;
        @media (min-width: 768px) and (max-width: 1199px) {
            // For Tablet screens
            max-width: 85%;
        }
    
        @media screen and (max-width: 767px) {
            // For mobile screens
            max-width: 100%;
        }
    }

    .card {
        margin:15px 0px;
        background-color: #333;
        border: solid 1px #777;
        border-radius: 15px;
        padding: 20px;
        color: #777;
        height: fit-content;

        .cardTitle {
            font-size: 20px;
            margin-bottom: 10px;
            font-weight: bold;
            color: white;
        }

        .cardDescription{
            //
        }

        @media (min-width: 992px) and (max-width: 1199px) {
            // For Tablet screens - intermediate resolution
            height: 240px;
        }

        @media (min-width: 1200px) {
            // For Desktop Screens
            height: 185px;
        }
    }
}

.joinUsSection {
    border-bottom: solid 1px #777;
    background-image: url("./../../public/assets/images/banner2.jpg");
    background-size: 100%;
    background-size: cover;
    text-align: left;
    
    .descriptionText{
        max-width: 100%;
    }

    @media (min-width: 768px) and (max-width: 1199px) {
        // For Tablet screens
    }

    @media screen and (max-width: 767px) {
        // For mobile screens
        text-align: center;
        .descriptionText{
            color: white;
            max-width: 400px;
            margin: auto;
        }
    }
}