/*Logo Styles*/
.logo{width: 115px; margin:auto;}
.logoWhite {filter: brightness(100%);}
.logoBlack {filter: brightness(0%);}
.extraSmall{width: 50px;}
/*End of Logo Styles*/


.header{
    background-color: #000;
    color: #777;
    border-bottom: solid 1px #777;
    padding: 10px 0;
    .navbar{align-items: flex-start; justify-content: space-between;}
    .navbar .active{color: #ffc107 !important;}
    .navbar .navbar-nav a:hover{color: #FFF !important;}
    .navbar .navbar-nav a.active:hover{color: #ffc107 !important;}
    .btn.customButton{
        width: 130px !important;
        height: 40px !important;
    }
}

.hero {
    background-image: url("./../../public/assets/images/radialOverlay.svg");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: top;
    background-color: black;
    text-align: center;
    padding: 80px 0px;

    @media screen and (max-width: 768px) {
        background-size: 180%;
    }
    
    .bannerImage {
        // background-image: url("./../../public/assets/images/grid.png");
        background-image: url("./../../public/assets/images/banner.png");
        background-size: 100%;
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: bottom;
        height: 168px;

        @media (min-width: 768px) and (max-width: 1199px) {
            background-size: 130%;    
        }
    
        @media screen and (max-width: 767px) {
            background-size: 180%;
        }
        
        // .button.customButton {
        //     width: 190px !important;
        //     height: 50px !important;
        // }
    }
}

.hero.homePageHero {
    padding-bottom: 0px;
}

.banner {
    overflow: hidden;
    display: flex;
    align-items: center;
    height: fit-content;
    max-height: 200px;
}

.footer{
    background-color: #0c0c0c;
    color: #555;
   
    .footerContent{
        padding-top: 80px;
        padding-bottom: 30px;
    }

    .copyRight{
        background-color: black;
        border-top: solid 1px #2e2e2e;
        padding: 20px 0px;
        font-size: 14px;
    }
}

.socialMediaLinks{
    font-size: 25px;
    li{padding: 6px; cursor: pointer;}
    li:hover{color: #ffc107;}
    li:first-child{padding-left: 0px;}
}