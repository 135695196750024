html, body {
  margin: 0;
  height: 100%;
}

#root,.pagea{height: 100%; color: #777;}

* {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
}

img{width: 100%;}

// .page{
//   display: flex; 
//   flex-direction: column;
//   height: 100%;
//   color: #aaa;
// }
// .pageBody{
//   padding-top: 100px;
//   padding-bottom: 100px;
//   flex-grow: 1;
//   /* color: #aaa; */
//   // background-color: #271d2f;
//   background-color: black;
// }
// .pageHeading{
//   margin-bottom: 40px;
// }
// .pageContent{}
// .section{
//   padding-top: 100px;
//   padding-bottom: 100px;
// }

/*
 * Common global classes
 */
.w100{width: 100%;}
.h100{height: 100%;}
.wh100{width: 100%; height: 100%;}
.margin0{margin: 0px;}
.margin10{margin: 10px;}
.margin20{margin: 20px;}
.margin30{margin: 30px;}
.margin40{margin: 40px;}
.margin50{margin: 50px;}
.marginTB0{margin-top: 0px; margin-bottom: 0px;}
.marginTB10{margin-top: 10px; margin-bottom: 10px;}
.marginTB20{margin-top: 20px; margin-bottom: 20px;}
.marginTB30{margin-top: 30px; margin-bottom: 30px;}
.marginTB40{margin-top: 40px; margin-bottom: 40px;}
.marginTB50{margin-top: 50px; margin-bottom: 50px;}
.padding0{padding: 0px;}
.padding10{padding: 10px;}
.padding20{padding: 20px;}
.padding30{padding: 30px;}
.padding40{padding: 40px;}
.padding50{padding: 50px;}
.paddingTB0{padding-top: 0px; padding-bottom: 0px;}
.paddingTB10{padding-top: 10px; padding-bottom: 10px;}
.paddingTB20{padding-top: 20px; padding-bottom: 20px;}
.paddingTB30{padding-top: 30px; padding-bottom: 30px;}
.paddingTB40{padding-top: 40px; padding-bottom: 40px;}
.paddingTB50{padding-top: 50px; padding-bottom: 50px;}
.paddingTB80{padding-top: 80px; padding-bottom: 80px;}

.colorAAA{color: #AAA !important;}
.colorBlack{color: #000 !important;}
.bgBlack{color: #000 !important;}
.colorWhite{color: white !important;}
.bgWhite{background-color: white !important;}
.colorThemeBlue{color: #4B34DC !important;}
.colorYellow{color: #ffc107 !important;}
.bgYellow{background-color: #ffc107 !important;}
.colorPurple{color: #271d2f !important;}
.bgPurple{background-color: #271d2f !important;}
.colorOrange{color: #ff800e;}
.bgOrange{color: #ff800e;}
.colorGrey{color: grey !important;}
.bgGrey{background-color: grey !important;}
.colorBlue{color: #052938;}
.bgBlue{background-color: #052938;}
.colorRed{color: red;}
.bgRed{background-color: red;}

ul{list-style-type: none; margin: 0px !important; padding: 0px !important;}


// h2{color: #EEE; margin: 0px !important; text-transform: uppercase;}
// h4{color: #EEE; font-weight: normal !important; font-size: 18px !important; text-transform: uppercase; margin-bottom: 15px !important;}
// h6{color: #AAA}


.displayNone{display: none;}
.displayFlex{display: flex;}
.justifyContentSpaceBetween{justify-content: space-between;}
.alignItemsCenter{align-items: center;}
.alignItemsFlexStart{align-items: flex-start;}
.textJustify{text-align: justify;}



a{text-decoration: none !important; color: inherit !important;}
a:hover{color: #ffc107 !important;}
a:hover{
  .gameCard{
    img{
      filter: brightness(1.5);
    }
    .aboutCard{
      color: white;
      .gameName{
        color: #ffc107; 
      }
      .playDemoLink{
          text-decoration: underline !important;
      }
    }
  }

  .gameCard.disabled{
    img{
      filter: brightness(0.4);
    }
    .aboutCard{
        color: #777;
        .gameName{
            color: white;
        }
        .playDemoLink{
            color: #777 !important;
        }
    }
  }
}
.customButton{
  border-radius: 50px !important;
  height: 35px;
  line-height: 22px;
  color: white;
}
.blueTheme.filled{background-color: #4B34DC; color: white; border-color: #604ddc;}
.blueTheme.filled:hover{background-color: #381bf6; color: white;  border-color: #604ddc;}
.blackTheme.filled{background-color: black; color: white; border-color: #4B34DC;}
.blackTheme.filled:hover{background-color: #4B34DC; color: white; border-color: #604ddc;}
.yellowTheme.filled{background-color: #ffc107; color: black;}
.yellowTheme.filled:hover{background-color: #fdd14e;}
.customButton.disabled{border-color: grey; color: grey; cursor: pointer;}


